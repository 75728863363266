import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const EmailInput = ({ type, value, onChange, placeholder, name, required }) => {
    return (
        <div className="relative w-[100%]">
            <FontAwesomeIcon icon={faEnvelope} size='xl' className="absolute left-2 mr-5 top-4 text-gray-600" />
            <input
                type={type}
                name={name}
                required={required}
                className="pl-10 rounded-4xs-9 box-border w-[100%] h-[54px]  items-center justify-start focus:border-black py-[13.344263076782227px] px-[17.79235076904297px] border-[1.1px] border-solid border-grey-600 placeholder:text-gray-600 placeholder:text-[0.834rem]"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default EmailInput