import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import Creators from "../pages/Creators";
import Business from "../pages/Business";
import Invest from "../pages/Invest";
import Contact from "../pages/Contact";
import Landing from "../pages/Landing";
import ErrorPage from "../pages/ErrorPage";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Landing />} />
      <Route path="/about" element={<About />} />
      <Route path="/creators" element={<Creators />} />
      <Route path="/business" element={<Business />} />
      <Route path="/invest" element={<Invest />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
