import React, { Fragment, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import phone from "../assets/LandingHero.png";
import PhoneForMobile from "../assets/PhoneForMobile.png";
import axios from "axios";
import { WaitlistSuccess } from "../components/WaitlistSuccess";
import { WaitlistError } from "../components/WaitlistError";
import ToggleButton from "../components/ToggleButton";
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import '../index.css'
import 'react-phone-number-input/style.css'; // Import the CSS for react-phone-number-input
import CustomInput from "../components/inputs/CustomInput";
import EmailInput from "../components/inputs/EmailInput";

const Landing = ({ title }) => {
  const waitlistEndpoint =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_WAITLISTAPI_PROD
      : process.env.REACT_APP_WAITLISTAPI_DEV;

  const termsAndConditions =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_TERMS_LINK_PROD
      : process.env.REACT_APP_TERMS_LINK_DEV;

  const privacyPolicy =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_PRIVACY_PROD
      : process.env.REACT_APP_PRIVACY_DEV;

  const [contact, setContact] = useState({
    email: "",
    phone: "",
  });

  const [selected, setSelected] = useState("phone");
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleChange = (value) => {
    setContact({ ...contact, phone: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const contactInfo = selected === "email" ? { email: contact.email } : { phoneNumber: contact.phone };
    console.log(contactInfo)
    console.log('Waitlist Endpoint:', waitlistEndpoint);
    axios
      .post(waitlistEndpoint, JSON.stringify(contactInfo))
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setContact({ email: "", phone: "" });
          setShowModal(true);
        }
      })
      .catch((err) => setShowErrorModal(true));
  };

  const isMobile = window.innerWidth < 300;

  return (
    <Fragment>
      <Helmet>
        <title>Home | luupli</title>
        <meta
          name="description"
          content="Social media is unfair and broken. Join the movement trying to set things right."
        />
      </Helmet>
      <div className="bg-stone-50 max-md:pl-5">
        <div className="gap-0 flex flex-col md:ml-7 xl:ml-12 md:flex-row items-stretch">
          <div className="flex flex-col items-stretch w-[50%] max-md:w-full">
            <div className="flex flex-col items-stretch">
              <div className="text-zinc-950 lg:text-[3.50rem] mt-5 md:mt-24 leading-[60px] font-['Inter'] font-extrabold tracking-tighter max-md:max-w-full text-[2.5rem] max-md:leading-10">
                Social media is unfair and broken
              </div>
              <div className="text-gray-700 text-[1.09375rem] md:text-[1.25rem] font-['Inter'] font-normal leading-6 md:mt-4 tracking-tight mt-2 max-md:max-w-full">
                Join the movement trying to set things right. <br /> Try our BETA version now
              </div>
              <div className="flex mt-4 mb-0">
                <ToggleButton selected={selected} setSelected={setSelected} />
              </div>
              <form
                aria-label="waitlist form"
                onSubmit={handleSubmit}
                className="items-stretch w-[100%] gap-2 flex flex-row justify-between md:gap-2 mt-0"
              >
                <div className="items-stretch xl:w-[100%] w-[90%] flex justify-between py-3.5 rounded-lg">
                  {selected === "email" ? (
                    <EmailInput
                      name="email"
                      type="email"
                      required
                      className=""
                      placeholder="Enter your email"
                      value={contact.email}
                      onChange={(e) => setContact({ ...contact, email: e.target.value })}
                    />
                  ) : (
                    <PhoneInput
                      inputComponent={CustomInput}
                      flags={flags}
                      defaultCountry="GB"
                      international={true}
                      value={contact.phone}
                      onChange={handleChange}
                      className="w-[100%] rounded-4xs-9 box-border  mr-2 h-[54px] items-center justify-start focus:border-black py-[13.344263076782227px] px-[17.79235076904297px] border-[1.1px] border-solid border-grey-600 placeholder:text-gray-600 placeholder:text-[0.834rem]"
                    />
                  )}
                </div>
                <button
                  aria-label="Submit"
                  type="submit"
                  className={`mt-3 mr-2 xl:mr-16 rounded-4xs-9 lg:px-5 ${isMobile ? "px-6" : ""} py-6 bg-green-600 flex flex-row h-14 items-center justify-center text-base text-black border-[2.2px] border-solid border-green-600 cursor-pointer lg:hover:bg-green-700`}
                >
                  <div className="text-black text-base font-['Inter'] tracking-normal whitespace-nowrap">
                    {isMobile ? "Join" : "Join the wait list"}
                  </div>
                  <FontAwesomeIcon className="hidden md:block pl-4" icon={faArrowRight} />
                </button>
              </form>
              <div className="text-black text-[0.8375rem] font-['Inter'] tracking-tight xl:w-[90%] md:mt-4 max-md:mt-0">
                <p className="mt-0">
                  By choosing to download our app, you also agree to our{" "}
                  <span className="cursor-pointer underline">
                    <a
                      href={termsAndConditions}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black"
                    >
                      terms and conditions
                    </a>
                  </span>
                  , including our{" "}
                  <a
                    href={privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-black cursor-pointer"
                  >
                    privacy policy
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch w-[60%] md:[60%] xl:w-[60%] xl:-mt-20 max-md:w-full">
            <img
              loading="lazy"
              src={phone}
              className="hidden md:block aspect-[0.99] object-contain object-center w-full overflow-hidden grow"
              alt="Broken Phone"
            />
            <img
              loading="lazy"
              src={PhoneForMobile}
              className="md:hidden w-full scale-90"
              alt="Broken Phone"
            />
          </div>
        </div>
      </div>
      {showModal && <WaitlistSuccess onClose={() => setShowModal(false)} />}
      {showErrorModal && <WaitlistError onClose={() => setShowErrorModal(false)} />}
    </Fragment>
  );
};

export default Landing;
