import React from "react";
import photo1 from "../assets/about1.png";
import phonePhoto from "../assets/aboutonphone.png";
import photo2 from "../assets/about2.png";
import { Helmet } from "react-helmet";
// import background from "../assets/background.png";

const About = () => {
  const isMobile = window.innerWidth < 768;
  return (
    <section className="items-center md:flex md:flex-col md:overflow-x-hidden">
      <Helmet>
        <title>About-Us | luupli</title>
        <meta name="description \n" content="We're luupli" />
      </Helmet>
      <section className="self-center w-screen mx-auto md:mt-10 px-5 md:px-0 md:w-screen  md:flex md:flex-col md:self-stretch">
        <div className="self-center w-screen md:w-screen md:ml-0 ">
          <div className="md:gap-5 md:flex md:items-stretch md:w-full lg:w-full">
            <div className="items-center md:justify-center md:mt-16 md:ml-10  lg:mt-12 ">
              <h1 className="text-green-950 text-[1.875rem] mt-5 lg:text-[3.50rem] leading-8 lg:leading-tight  font-['Inter'] tracking-tighter w-[90%]  mb-2 md:w-[95%] lg:w-[90%] xl:mt-16">
                <span className="font-extrabold">We’re luupli</span>
                <br />
                <span className=" font-bold font-['Inter']  ">
                  An unbroken circle of human connections
                </span>
              </h1>
              <p className="text-green-950 text-[1.09375rem] lg:text-[1.875rem] md:mt-6  mt-0 font-normal font-['Inter'] leading-6 lg:leading-tight tracking-tighter w-[90%] ">
                At luupli, we strive to weave the digital threads of connection,
                creating an inclusive tapestry where every voice finds its
                rightful loop.
              </p>
            </div>

            <div className=" w-[100%]  md:mt-12 lg:mt-32 md:w-[100%] lg:w-[60%] md:mr-24  md:ml-10 lg:mr-28   md:scale-150 md:items-stretch">
              <div className="">
                <img
                  loading="lazy"
                  src={photo1}
                  className="hidden md:block aspect-[0.76] md:aspect-[0.71] object-cover w-full max-w-sm  object-center self-stretch rounded-[37.38094711303711px] lg:w-full lg:scale-150"
                  alt="A group of people smiling"
                />

                {/* Image for screens smaller than md */}
                <img
                  loading="lazy"
                  src={phonePhoto}
                  className="md:hidden aspect-[1.31]  object-cover w-[85%]  h-full object-center self-stretch rounded-[37.38094711303711px]"
                  alt="A group of people smiling"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="justify-center items-center self-stretch  w-screen mx-auto md:bg-zinc-100  md:flex md:flex-col md:-mt-20 lg:-mt-0 xl:-mt-16">
        <div className="self-center ml-0 mb-16 ">
          <div className=" md:gap-5 md:flex md:items-stretch">
            <div className="flex flex-col items-center md:ml-10 w-[100%] ">
              <p className="text-green-950 text-[1.09375rem] lg:text-[1.775rem]  font-medium md:font-normal font-['Inter'] leading-6 md:leading-tight tracking-tight w-[90%] mb-10 lg:mx-auto md:w-[100%] md:ml-10 md:mr-2 md:mt-28">
                Grounded in the foundational belief that everyone regardless of
                their background deserves a transparently equal platform, we are
                on a mission to redefine how users and creators thrive.{" "}
                {isMobile ? <br /> : null}
                {isMobile ? <br /> : null}
                Prioritising user privacy, we're dedicated to fostering genuine
                connections ranging from personal ties among friends and family,
                to impactful relationships with global influencers.......
              </p>
            </div>
            <div className="flex flex-col items-stretch w-[85%] mx-auto  md:ml-16 md:w-[30%] md:mr-14 md:mt-24 lg:mt-24">
              <img loading="lazy" src={photo2} alt="A collage of photos" />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default About;
