import React, { useState, Fragment } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import heroImage from "../assets/creator1.png";
import heroImageMobile from "../assets/creator1mobile.png";
import photo2 from "../assets/creator2.png";
import photo3 from "../assets/creator3.png";
import photo4 from "../assets/creator4.png";
import { CreatorsWaitlist } from "../components/CreatorsWaitlist";
import { Helmet } from "react-helmet";

const Creators = () => {
  const waitlistEndpoint =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_WAITLISTAPI_PROD
      : process.env.REACT_APP_WAITLISTAPI_DEV;

  const [email, setEmail] = useState({
    email: "",
  });

  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    const emailJSON = JSON.stringify(email);
    e.preventDefault();
    console.log("email", emailJSON);
    console.log("submitting form....");
    axios
      .post(waitlistEndpoint, emailJSON)
      .then((response) => {
        setEmail({ email: "" });
        setShowModal(true);
      })
      .catch((err) => console.log(err));
  };

  const isMobile = window.innerWidth < 768;
  const isMobile1 = window.innerWidth < 300;
  return (
    <section className="flex flex-col">
      <Helmet>
        <title>Creators | luupli</title>
        <meta
          name="description"
          content="Creating a global community that's open, fair, and full of
              happiness for everyone."
        />
      </Helmet>
      <div className="justify-center self-center w-screen ml-10  md:mt-10">
        <div className=" md:gap-5 md:flex md:items-stretch md:w-full">
          <div className=" md:flex md:flex-col md:items-stretch md:w-[61%] md:ml-10 xl:mt-16">
            <h1 className=" text-green-950 text-[1.875rem] lg:text-[3.50rem]  font-bold font-['Inter']  leading-8 md:leading-tight tracking-tighter w-[90%] md:w-[100%]  md:mt-24 lg:mt-20 xl:mt-28">
              Creating a global community that's open, fair, and full of
              happiness for everyone.
            </h1>
          </div>
          <div className="items-center w-[90%] mr-10 mt-2 md:w-[65%] lg:w-[45%] md:mr-40 md:items-stretch md:mt-6 md:scale-150 ">
            <img
              loading="lazy"
              src={heroImage}
              alt="A smiling person"
              className=" hidden md:block object-cover aspect-[0.76] md:aspect-[0.71] object-center w-full self-stretch"
            />

            <img
              loading="lazy"
              src={heroImageMobile}
              alt="A smiling person"
              className=" md:hidden object-cover  aspect-[1.11] object-center w-[95%] self-stretch"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch flex w-[100%]  md:bg-zinc-100 px-5 pb-5 md:-mt-16  md:flex-col xl:-mt-24">
        <div className="self-center w-[100%] mt-1  md:mt-20 md:mb-12   md:w-full ">
          <div className=" md:gap-5 md:flex md:items-stretch">
            <div className=" items-stretch w-[90%] md:w-[64%] md:flex md:flex-col">
              <p className="text-green-950 text-[1.09375rem] lg:text-[1.775rem]  font-medium md:font-normal font-['Inter'] leading-6 md:leading-tight tracking-tight w-[100%] md:w-[80%] md:ml-6  md:my-auto">
                At luupli, our mission is to empower creators and users around
                the world, by fostering collaboration, equitable opportunities,
                and economic empowerment. {isMobile ? <br /> : null}
                {isMobile ? <br /> : null}
                We are dedicated to creating a safe and inclusive space for all
                users, to thrive and prosper on social media.
              </p>
            </div>
            <div className=" items-stretch w-[90%] md:w-[34%] md:flex md:flex-col md:mr-16 ">
              <img
                loading="lazy"
                src={photo2}
                alt="a collage of photos"
                className=" bg-transparent  object-cover aspect-[1.41] object-center w-full self-stretch"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch flex w-[100%]  flex-col  bg-white md:mt-24">
        <div className="self-center w-full  md:-ml-3.5 md:mt-10 md:mb-24">
          <div className=" md:gap-4 md:flex md:flex-row md:items-stretch">
            <div className="flex flex-col items-stretch  md:w-[23%] md:ml-12 ">
              <div className=" ">
                <img
                  loading="lazy"
                  src={photo3}
                  className=" hidden md:block aspect-[0.76] object-cover object-center w-[70%] bg-opacity-20 self-stretch rounded-3xl"
                  alt="A smiling Person"
                />
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] ml-3 md:w-[77%] md:ml-5">
              <h1 className="text-green-950 text-[1.09375rem] lg:text-[1.775rem] font-['Inter'] font-medium sm:font-light leading-6  md:leading-8 tracking-tighter w-[100%] md:mt-7 md:w-[80%] md:mx-auto ">
                At luupli, we are committed to reshaping the social media
                landscape by championing inclusivity, transparency, and
                empowerment. We prioritise providing a haven for users and
                Creators from underserved global communities, ensuring they
                prosper without hindrance.
                {isMobile ? <br /> : null}
                {isMobile ? <br /> : null}
                Our unique approach involves sharing our platform's revenue
                transparently, guaranteeing equitable rewards for every
                'Luuper.’
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Fragment>
        <section className="justify-end items-center self-stretch flex w-[100%] md:mx-auto flex-col md:bg-zinc-100 mt-10 md:mt-8 pl-3 md:py-5">
          <div className="items-start self-center flex w-[100%] flex-col md:ml-10 md:my-8 md:pr-5">
            <div className="self-center  w-full ">
              <div className=" md:gap-5 md:flex md:flex-row">
                <div className="flex flex-col items-stretch w-[90%] md:w-[65%]  ">
                  <div className="items-start flex flex-col ml-3 ">
                    <h1 className="text-green-950 text-[1.775rem] lg:text-[3.1395rem] font-['Inter'] font-bold leading-9 md:leading-none tracking-tight w-[100%]  mb-4 md:w-[90%]">
                      We are currently running a special programme for our first
                      1000 creators on the platform
                    </h1>
                    <p className="text-green-950 text-[ 1.09375rem] md:mt-3 lg:text-[1.51975rem] font-['Inter']  font-medium md:font-normal leading-6 md:leading-tight tracking-tight w-[90%] mt-0 md:w-[90%] md:text-xl ">
                      which will involve luupli share options and exclusive
                      access to the CEO, as well as the ability to influence our
                      product in a unique way. Ready to create with us? Join our
                      BETA waitlist today and be among the first to experience
                      innovation in action
                    </p>
                  </div>
                  <div className="items-start  w-[95%] ml-3 md:mt-3  mt-1 md:w-[80%] md:flex flex flex-row md:justify-between md:gap-4">
                    <form
                      onSubmit={handleSubmit}
                      className="item-center  self-stretch flex w-[50%] md:w-[100%] flex-row grow shrink-0 basis-auto justify-between gap-2.5 md:gap-4  rounded-md "
                    >
                      {/* <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/465e39d5-9c2d-410c-9053-ce476f14f7a0?apiKey=f5f7c7073a204785b84af34315c6213b&"
                      className="aspect-square object-cover object-center w-[26px] self-center shrink-0 my-auto"
                      alt=""
                    /> */}
                      <input
                        name="email"
                        type="email"
                        required
                        className="rounded-4xs-9 box-border w-[90%] md:w-[90%] focus:border-black mb-3 h-[54px] flex flex-row items-center justify-start py-[13.344263076782227px] px-[17.79235076904297px] gap-[8.9px] border-[1.1px] border-solid border-grey-600 placeholder:text-gray-600"
                        placeholder="✉ Enter your email"
                        value={email.email}
                        onChange={handleChange}
                      />

                      <button
                        aria-label="Submit"
                        type="submit"
                        className={` mr-2 xl:mr-16 rounded-4xs-9 lg:px-5 ${
                          isMobile1 ? "px-6" : ""
                        } py-6 bg-green-600 flex flex-row h-14 items-center justify-center text-base text-black border-[2.2px] border-solid border-green-600 cursor-pointer lg:hover:bg-green-700`}
                      >
                        <div className="text-black text-base  font-['Inter'] tracking-normal whitespace-nowrap">
                          {isMobile ? "Join" : "Join the wait list"}
                        </div>
                        <FontAwesomeIcon
                          className="hidden md:block pl-4"
                          icon={faArrowRight}
                        />
                      </button>
                    </form>
                  </div>
                </div>
                <div className="flex flex-col items-stretch w-[90%]  md:w-[33%] md:mr-20 ">
                  <div className="items-start flex flex-col">
                    <img
                      loading="lazy"
                      src={photo4}
                      className=" hidden md:block aspect-[0.79] object-cover object-center w-full self-stretch rounded-[42.769317626953125px]"
                      alt="A man on his phone"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {showModal && <CreatorsWaitlist onClose={() => setShowModal(false)} />}
      </Fragment>
    </section>
  );
};
export default Creators;
