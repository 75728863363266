import { motion } from "framer-motion";

const TOGGLE_CLASSES = "text-sm font-medium flex items-center gap-2 px-4 py-2 transition-colors relative z-10";

const ToggleButton = ({ selected, setSelected }) => {
    return (
        <div className="relative flex w-25 h-5 items-center rounded-full bg-gray-200 p-1">
            <button
                className={`${TOGGLE_CLASSES} ${selected === "phone" ? "text-white" : "text-gray-800"} relative`}
                onClick={() => setSelected("phone")}
                style={{ backgroundColor: "transparent" }}
            >
                Phone
            </button>
            <button
                className={`${TOGGLE_CLASSES} ${selected === "email" ? "text-white" : "text-gray-800"} relative`}
                onClick={() => setSelected("email")}
                style={{ backgroundColor: "transparent" }}
            >
                Email
            </button>
            <div className={`absolute inset-0 z-0 flex ${selected === "email" ? "justify-end" : "justify-start"}`}>
                <motion.span
                    layout
                    transition={{ type: "spring", damping: 15, stiffness: 250 }}
                    className="h-full w-1/2 rounded-full bg-green-500"
                />
            </div>
        </div>
    );
};

export default ToggleButton;
