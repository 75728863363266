import React, { Fragment, useState } from "react";
import { ContactModal } from "../components/ContactModal";
import messageIcon from "../assets/contact.png";
import axios from "axios";
import { SuccessModal } from "../components/SuccessModal";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [h2Content, setH2Content] = useState("How did you find us?");

  const contactEndpoint =
    process.env.REACT_APP_ENV === "production"
      ? process.env.REACT_APP_CONTACTAPI_PROD
      : process.env.REACT_APP_CONTACTAPI_DEV;

  const handleCheckboxChange = (option) => {
    setSelectedOption(option);
    setH2Content(option);
    setUserInfo({ ...userInfo, howDidYouFindUs: option });
  };

  const [userInfo, setUserInfo] = useState({
    email: "",
    name: "",
    message: "",
    phoneNumber: "",
    howDidYouFindUs: "",
  });

  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    const userInfoJSON = JSON.stringify(userInfo);
    e.preventDefault();
    if (
      typeof userInfo.phoneNumber !== "string" ||
      userInfo.phoneNumber.trim() === ""
    ) {
      console.error("Invalid phone number");
      return;
    }
    console.log("userInfo:", userInfoJSON);
    axios
      .post(contactEndpoint, userInfoJSON)
      .then((response) => {
        console.log(response);
        setUserInfo({
          email: "",
          name: "",
          message: "",
          phoneNumber: "",
          howDidYouFindUs: "",
        });
        setSelectedOption(null);
        setH2Content("How did you find us?");

        setShowSuccessModal(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Helmet>
        <title>Contact-us | luupli</title>
        <meta name="description" content="Want to get in Touch with us?" />
      </Helmet>
      <section className="items-start flex flex-col">
        <main className="bg-white self-stretch flex w-screen flex-col px-5 ">
          <div className="self-center w-full  ">
            <div className=" md:flex md:flex-row md:ml-3 xl:ml-12 md:w-[90%] xl:w-[90%] ">
              <div className="flex flex-col items-stretch w-[90%] md:w-[61%] ">
                <div className="flex flex-col mt-3">
                  <div className="items-start flex w-[90%] md:w-[100%]  flex-col ">
                    <h2 className="items-start self-stretch flex flex-col">
                      <span className="text-neutral-600 font-['Inter'] text-[1.5rem] md:text[3.5rem] font-bold">
                        <span className="text-black">Get in </span>
                        <span className="text-neutral-600 text-opacity-100">
                          Touch
                        </span>
                      </span>
                      <p className="text-black font-['Inter'] text-[0.9rem] font-semibold leading-6 tracking-normal w-full mt-5 ">
                        Have questions or interested in getting involved with
                        Luupli? Let’s connect!
                      </p>
                    </h2>
                    <form
                      onSubmit={handleSubmit}
                      id="contact-us"
                      className="items-start font-['Inter'] self-stretch flex flex-col "
                    >
                      <input
                        name="name"
                        id="name-input"
                        type="text"
                        placeholder="Name*"
                        className="border border-[color:var(--Gray-5,#E0E0E0)]  focus:border-black w-[90%] md:w-[70%]   mt-10 px-5 py-3.5 border-solid"
                        required
                        value={userInfo.name}
                        onChange={handleChange}
                      />
                      <input
                        name="phoneNumber"
                        type="telephone"
                        placeholder="Phone number*"
                        className="border border-[color:var(--Gray-5,#E0E0E0)] focus:border-black w-[90%] md:w-[70%] mt-10 px-5 py-3.5 border-solid"
                        required
                        autoComplete="off"
                        value={userInfo.phoneNumber}
                        onChange={handleChange}
                      />
                      <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        className="border border-[color:var(--Gray-5,#E0E0E0)] focus:border-black w-[90%] md:w-[70%]  mt-10 px-5 py-3.5 border-solid"
                        required
                        value={userInfo.email}
                        onChange={handleChange}
                      />

                      <div
                        className="justify-between items-start border  border-[color:var(--Gray-5,#E0E0E0)] focus:border-black flex w-[90%] md:w-[70%]  mt-10 px-5 py-3.5 border-solid"
                        aria-label="Survey Question"
                        onClick={() => setShowModal(true)}
                      >
                        <h2
                          value={userInfo.howDidYouFindUs}
                          onChange={handleChange}
                          name="howDidYouFindUs"
                          className=" font-normal text-sm font-['Inter'] leading-6 tracking-normal self-center w-[90%] my-auto"
                        >
                          {h2Content}
                        </h2>
                        <img
                          loading="lazy"
                          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/55cd3908-feb4-4b7a-ba75-d2d55f369e6f?apiKey=f5f7c7073a204785b84af34315c6213b&"
                          className="aspect-[2] object-cover object-center w-3 self-center shrink-0 my-auto"
                          alt="Logo"
                        />
                      </div>
                      <textarea
                        value={userInfo.message}
                        onChange={handleChange}
                        name="message"
                        placeholder="Write Your message..."
                        className="custom-font text-zinc-500 custom font placeholder:font-['Inter'] text-sm leading-6 tracking-normal self-stretch focus:border-black w-[90%] md:w-[70%] resize-none items-start border border-[color:var(--Gray-5,#E0E0E0)] mt-5 pt-3 pb-20 px-5 border-solid "
                        required
                      />

                      <button
                        type="submit"
                        className="justify-center items-center bg-neutral-600 hover:bg-neutral-700 self-stretch flex flex-col mt-5 px-5 py-4 rounded-xl md:w-[75%]  cursor-pointer "
                      >
                        <span className="text-white text-xl font-bold uppercase self-center -mb-px">
                          send
                        </span>
                      </button>
                    </form>
                    <div className="items-start flex w-[90%] md:w-[100%] justify-between mt-8 md:gap-5 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="28"
                        viewBox="0 0 27 28"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_598_5336)">
                          <path
                            d="M22.7694 11.8315C22.7694 19.4212 13.0112 25.9267 13.0112 25.9267C13.0112 25.9267 3.25293 19.4212 3.25293 11.8315C3.25293 9.24344 4.28103 6.76139 6.11105 4.93136C7.94108 3.10134 10.4231 2.07324 13.0112 2.07324C15.5992 2.07324 18.0813 3.10134 19.9113 4.93136C21.7413 6.76139 22.7694 9.24344 22.7694 11.8315Z"
                            stroke="black"
                            strokeWidth="2.1685"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.011 15.0841C14.8075 15.0841 16.2638 13.6278 16.2638 11.8314C16.2638 10.0349 14.8075 8.57861 13.011 8.57861C11.2146 8.57861 9.7583 10.0349 9.7583 11.8314C9.7583 13.6278 11.2146 15.0841 13.011 15.0841Z"
                            stroke="black"
                            strokeWidth="2.1685"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_598_5336">
                            <rect
                              width="26.022"
                              height="26.022"
                              fill="white"
                              transform="translate(0 0.989258)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <address className="text-black not-italic  font-['Inter'] text-xs font-semibold leading-4 tracking-wide self-center w-[70%] md:w-[579px] grow shrink-0 basis-auto my-auto">
                        ADDRESS <br />
                        Luupli Limited, 71-75 Shelton Street, Covent Garden,
                        London, WC2H 9JQ
                      </address>
                    </div>
                    <div className="items-start flex w-[90%] md:w-[100%]  justify-between gap-5 mt-8">
                      <img
                        loading="lazy"
                        src={messageIcon}
                        className="aspect-square object-cover object-center w-[10%] md:w-[5%] shrink-0"
                        alt="Logo"
                      />

                      <div className="items-start flex flex-col md:flex-row md:gap-1.5 grow shrink-0 basis-auto ">
                        <div className="text-black font-['Inter'] text-xs font-semibold leading-5 tracking-wide underline self-stretch w-[90%] md:w-[20%]">
                          General enquiries :<br />
                          <a
                            href="mailto:hello@luupli.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            hello@luupli.com
                          </a>
                        </div>
                        <div className="hidden md:block bg-neutral-600 self-stretch  w-px h-[39px] flex-col" />
                        <div className="text-black mt-2 md:mt-0 font-['Inter'] text-xs font-semibold leading-5 tracking-wide underline self-stretch w-[70%] md:w-[20%]">
                          Creator enquiries :<br />
                          <a
                            href="mailto:Creators@luupli.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Creators@luupli.com
                          </a>
                        </div>
                        <div className=" hidden md:block bg-neutral-600 self-stretch w-px h-[39px] flex-col" />
                        <div className="text-black mt-2 md:mt-0 font-['Inter'] text-xs font-semibold leading-5 tracking-wide underline self-stretch w-[70%] md:w-[20%]">
                          Investors :<br />
                          <a
                            href="mailto:Invest@luupli.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Invest@luupli.com
                          </a>
                        </div>
                        <div className="hidden md:block bg-neutral-600 self-stretch w-px h-[39px] flex-col" />
                        <div className="text-black mt-2 md:mt-0 font-['Inter'] text-xs font-semibold leading-5 tracking-wide underline self-stretch w-[70%] md:w-[20%]">
                          Business: <br />
                          <a
                            href="mailto:advertise@luupli.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            advertise@luupli.com
                          </a>
                        </div>
                        <div className=" hidden md:block bg-neutral-600 self-stretch  w-px h-[39px] flex-col" />
                        <div className="text-black mt-2 md:mt-0 font-['Inter'] text-xs font-semibold leading-5 tracking-wide underline self-stretch w-[70%] md:w-[20%]">
                          recruitment & Jobs: <br />
                          <a
                            href="mailto:recruitment@luupli.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            recruitment@luupli.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-5 flex-col items-stretch w-[90%] md:scale-x-125 lg:scale-x-100 xl:scale-100  md:w-[100%] xl:w-[40%] md:mt-24  md:mr-32 lg:mr-4 ">
                <iframe
                  className=" aspect-[0.60] self-stretch object-cover object-center w-full  "
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9079031851356!2d-0.12354640000000001!3d51.5149056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccaaa0b0b7%3A0xbe144a0754857ae1!2s71-75%20Shelton%20St%2C%20London%20WC2H%209JQ%2C%20UK!5e0!3m2!1sen!2sgh!4v1697053657388!5m2!1sen!2sgh"
                  width="800"
                  height="600"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Luupli location"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </main>
      </section>
      <ContactModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
        selectedOption={selectedOption}
        onCheckboxChange={handleCheckboxChange}
      />
      {showSuccessModal && (
        <SuccessModal onClose={() => setShowSuccessModal(false)} />
      )}
    </Fragment>
  );
};

export default Contact;
