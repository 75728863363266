import React from 'react';
import ErrorPageSvg from '../assets/errorPage.svg';

const ErrorPage = () => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${ErrorPageSvg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
      className="flex text-center justify-center"
    >
      <div className="flex flex-col mt-28">
        <div className="font-extrabold text-xs lg:text-sm text-emerald-800 lg:ml-10">
          OOPS, LOOKS LIKE YOU’RE LOST
        </div>
        <div className="font-extrabold text-9xl md:text-9xl lg:text-[250px] xl:text-[317px] text-emerald-800 lg:-mt-6">
          404
        </div>
        <div className='font-extrabold text-xs lg:text-sm text-emerald-800 '>
          PAGE NOT FOUND
        </div>
      </div>


    </div>
  );
};

export default ErrorPage;
