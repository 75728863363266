import React from "react";

export const ContactModal = ({ isVisible, onClose, selectedOption, onCheckboxChange }) => {

  const handleCheckboxChange = (option) => {
    onCheckboxChange(option);
  };

  if (!isVisible) return null;

  const handleClose = (e) => {
    if (e.target.id === "wrapper") onClose();
  };

  return (
    <div
      className="fixed inset-0 w-full bg-black bg-opacity-25 backdrop-blur-sm  justify-center flex justify-self-center items-center "
      onClick={handleClose}
      id="wrapper"
    >
      <div className=" justify-center  items-center mr-10 bg-white flex w-[60%] md:w-[30%] md:mb-80 lg:mb-0  lg:w-[20%] flex-col pl-4 pr-8 rounded-xl shadow-lg shadow-black">
        <div className="justify-between items-start border-[color:var(--grey-500,#94969E)] bg-white self-stretch flex w-full gap-28 xl:gap-32 mt-4 pl-2 pr-2 py-2 rounded-md border-[0.39px] border-solid shadow-sm shadow-black">
          <div className="text-zinc-900 text-xs leading-[142.86%] tracking-tight self-center w-[40%] my-auto ">
            Referral
          </div>
          <input
            type="checkbox"
            id="check"
            onClick={onClose}
            checked={selectedOption === 'Referral'}
            onChange={() => handleCheckboxChange('Referral')}
            className="bg-zinc-500 self-stretch flex w-[20px] lg:w-[30px] h-[24px] flex-col grow shrink-0 basis-auto rounded "
          />
        </div>
        <div className="justify-between items-start border-[color:var(--grey-500,#94969E)] bg-white self-stretch flex w-[100%] gap-28 xl:gap-32 mt-3 pl-2 pr-2 py-2 rounded-md border-[0.39px] border-solid shadow-sm shadow-black">
          <div className="text-zinc-900 text-xs leading-[142.86%] tracking-tight self-center w-[40%]  my-auto">
            Social media
          </div>
          <input
            type="checkbox"
            id="check"
            onClick={onClose}
            checked={selectedOption === 'Social Media'}
            onChange={() => handleCheckboxChange('Social Media')}
            className="bg-zinc-300 self-stretch flex w-[20px] lg:w-[30px] h-[24px] flex-col grow shrink-0 basis-auto rounded "
          />
        </div>
        <div className="justify-between items-start border-[color:var(--grey-500,#94969E)] bg-white self-stretch flex w-[100%] gap-28 xl:gap-32 mt-3 pl-2 pr-2 py-2 rounded-md border-[0.39px] border-solid shadow-sm shadow-black">
          <div className="text-zinc-900 text-xs leading-[142.86%] tracking-tight self-center w-[40%] my-auto">
            Online search
          </div>
          <input
            type="checkbox"
            id="check"
            onClick={onClose}
            checked={selectedOption === 'Online Search'}
            onChange={() => handleCheckboxChange('Online Search')}
            className="bg-zinc-500 self-stretch flex w-[20px] lg:w-[30px] h-[24px] flex-col grow shrink-0 basis-auto rounded"
          />
        </div>
        <div className="justify-between items-start border-[color:var(--grey-500,#94969E)] bg-white self-stretch flex w-[100%] gap-28 xl:gap-32 mt-3 pl-2 pr-2 py-2 rounded-md border-[0.39px] border-solid shadow-sm shadow-black">
          <div className="text-zinc-900 text-xs leading-[142.86%] tracking-tight self-center w-[40%] my-auto">
            Creator community
          </div>
          <input
            type="checkbox"
            id="check"
            onClick={onClose}
            checked={selectedOption === 'Creator Community'}
            onChange={() => handleCheckboxChange('Creator Community')}
            className="bg-zinc-500 self-stretch flex w-[20px] lg:w-[30px] h-[24px]  flex-col grow shrink-0 basis-auto rounded"
          />
        </div>
        <div className="justify-between items-start border-[color:var(--grey-500,#94969E)] bg-white self-stretch flex w-[100%] gap-28 xl:gap-32 mt-3 mb-4 pl-2 pr-2 py-2 rounded-md border-[0.39px] border-solid shadow-sm shadow-black">
          <div className="text-zinc-900 text-xs leading-[142.86%] tracking-tight self-center w-[40%] my-auto">
            Investors community
          </div>
          <input
            type="checkbox"
            id="check"
            onClick={onClose}
            checked={selectedOption === 'Investors community'}
            onChange={() => handleCheckboxChange('Investors community')}
            className="bg-zinc-500 self-stretch flex w-[20px] lg:w-[30px] h-[24px] flex-col grow shrink-0 basis-auto -mt-px rounded"
          />
        </div>
      </div>
    </div>
  );
};
