import React from "react";
import flash from "../assets/flash.png";
import handshake from "../assets/image 1.png";
import globe from "../assets/global.png";
import shield from "../assets/security.png";
import investHero from "../assets/business1.png";
import investPhone from "../assets/businessonphone.png";
import photo1 from "../assets/about2.png";
import searchIcon from "../assets/search-normal.png";
import shieldTick from "../assets/shield-tick.png";
import messageIcon from "../assets/sms-tracking.png";
import enjoyIcon from "../assets/enjoy the journey.png";
import { Helmet } from "react-helmet";

const Invest = () => {
  return (
    <section className="flex flex-col overflow-x-hidden">
      <Helmet>
        <title>Invest | luupli</title>
      </Helmet>
      <article className="self-stretch w-screen bg-white pl-5 md:pl-0">
        <div className="items-center self-center w-full pr-5 ">
          <div className="self-center ml-0 md:w-full ">
            <div className=" md:flex ">
              <div className="items-stretch w-[90%] md:mt-20 md:ml-5">
                <h1 className="text-green-950 lg:text-[3.6rem] text-[1.875rem] font-['Inter'] leading-[28px] md:leading-none tracking-tight  w-[95%] md:w-[100%] xl:w-[100%] md:mx-auto md:mt-36">
                  <span className="font-bold">
                    Invest in the Future with luupli and{" "}
                  </span>
                  <span className=" font-bold font-['Inter'] lg:text-[3.6rem] text-[1.875rem]">
                    Secure Your Share in Innovation
                  </span>
                </h1>
              </div>
              <div className="flex flex-col mr-32  items-stretch w-[100%] md:w-[90%] xl:w-[70%] md:ml-5 md:mr-20 xl:mr-36">
                <img
                  loading="lazy"
                  src={investHero}
                  className="hidden md:block aspect-[0.50] object-cover object-center w-full self-stretch rounded-[37.38094711303711px]"
                  alt="Investors working"
                />
                <img
                  loading="lazy"
                  src={investPhone}
                  className="md:hidden aspect-[1.57] object-cover object-center w-[90%] self-stretch rounded-[37.38094711303711px]"
                  alt="Investors working"
                />
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="self-stretch flex  mx-auto flex-col md:bg-zinc-100  px-5 py-5 md:-mt-52 lg:-mt-72 xl:-mt-96">
        <div className="self-center ml-0 w-full md:ml-6  mb-12 ">
          <div className=" md:gap-5 md:flex">
            <div className="flex flex-col items-stretch w-[95%]">
              <p className="text-green-950 font-['Inter'] text-[1.09375rem] lg:text-[1.775rem] font-normal sm:font-extralight leading-6 md:leading-tight tracking-tight w-[90%] xl:w-[90%]  my-auto">
                Are you an investor seeking opportunities that combine
                innovation, impact, and growth potential? Look no further than
                luupli and our exciting investment opportunity through Seedfast.
              </p>
            </div>
            <div className="flex flex-col items-stretch w-[90%] md:w-[36%] md:mx-auto mt-6 md:mr-28">
              <img loading="lazy" src={photo1} alt="Happy People" />
            </div>
          </div>
        </div>
      </article>
      <article className="self-stretch flex w-screen flex-col bg-white md:mt-4 py-4">
        <h2 className="text-green-950 ml-10 text-[1.93581rem] lg:text-[2.46313rem] md:text-center xl:ml-14 font-['Inter'] font-bold md:leading-[49.212px] tracking-tighter  w-[65%] md:w-[75%]">
          Here's why you should consider investing in luupli:
        </h2>
        <div className="items-start content-start self-center flex md:flex-wrap ml-5 w-[90%] md:w-[70%]  flex-col mt-9 mb-0">
          <div className="self-stretch">
            <div className=" md:gap-5 md:flex">
              <div className="flex flex-col items-stretch w-[90%] md:w-6/12">
                <div className="items-start self-stretch flex flex-col ">
                  <img
                    loading="lazy"
                    src={flash}
                    className="aspect-square object-cover object-center w-16 shrink-0"
                    alt="Groundbreaking Disruption"
                  />
                  <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.037rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%] ">
                    Groundbreaking Disruption:
                  </h3>
                  <p className="self-stretch text-green-950 font-['Inter'] text-[0.84969rem] lg:text-[1.125rem] leading-7 w-[90%] md:w-[100%]  mt-1">
                    luupli is more than a social media platform; it's a
                    visionary idea poised to disrupt the entire social media
                    industry. Our innovative approach and commitment to positive
                    change set us apart. By investing in luupli, you're joining
                    the journey of transforming how people connect and engage
                    online.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-stretch w-[90%] md:w-6/12">
                <div className="items-start self-stretch flex flex-col ">
                  <img
                    loading="lazy"
                    src={handshake}
                    className="aspect-[1.6] object-cover object-center mb-2 w-16 shrink-0 md:mt-4"
                    alt="Mission-Based Partnership"
                  />
                  <h3 className="flex-[1_0_0] text-green-950 text-[1.037rem] lg:text-[1.4rem] font-['Inter'] font-bold leading-7 w-[90%] md:w-[100%] ">
                    Mission-Based Partnership:
                  </h3>
                  <p className="self-stretch text-green-950 text-[0.84969rem] lg:text-[1.125rem] font-['Inter'] leading-7 w-[90%] md:w-[100%] mt-1">
                    luupli is a mission-driven company that believes in the
                    power of purposeful technology. Our mission aligns with your
                    ESG (Environmental, Social, and Governance) investment
                    goals, ensuring that your investment has positive impact on
                    society and the environment. Partnering with luupli is an
                    investment in both profit and purpose.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="items-start content-start flex-wrap self-center flex ml-5 w-[90%] md:w-[70%]  flex-col mt-2 md:mb-16">
          <div className="self-stretch mt-10">
            <div className=" md:gap-5 md:flex md:flex-row">
              <div className="flex flex-col items-stretch w-[90%] md:w-6/12">
                <div className="items-start self-stretch flex flex-col ">
                  <img
                    loading="lazy"
                    src={globe}
                    className="aspect-square object-cover object-center w-16 shrink-0"
                    alt="Expansive Market Opportunity"
                  />
                  <h3 className="flex-[1_0_0] text-green-950 text-[1.037rem] lg:text-[1.4rem] font-['Inter'] font-bold leading-7 w-[90%] md:w-[100%]  mt-4">
                    Expansive Market Opportunity:
                  </h3>
                  <p className="self-stretch text-green-950 text-[0.84969rem] lg:text-[1.125rem] font-['Inter'] leading-7 w-[90%] md:w-[100%] mt-1">
                    Social media is a dynamic and continuously growing industry
                    with boundless opportunities. luupli is well-positioned to
                    capture a significant market share and deliver sustained
                    growth over the next decade. As we innovate and expand, your
                    investment grows with us.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-stretch w-[90%] md:ml-5 md:w-6/12">
                <div className="-start self-stretch flex flex-col">
                  <img
                    loading="lazy"
                    src={shield}
                    className="-square object-cover object-center w-16 shrink-0"
                    alt="Competitive Seedfast Terms"
                  />
                  <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.037rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%] mt-4">
                    Competitive Seedfast Terms:
                  </h3>
                  <p className="self-stretch text-green-950 text-[0.84969rem] lg:text-[1.125rem] font-['Inter'] leading-7 w-[90%] md:w-[100%] mt-1">
                    luupli offers investors a compelling package with
                    competitive discount rates and conversion events through our
                    seedfast. This ensures that your investment has the
                    potential for significant returns as luupli continues to
                    grow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      <section className="self-stretch flex w-[100%] flex-col md:bg-gradient-to-br from-lime-100 via-white to-emerald-100 md:mt-24 px-5 py-10 md:mx-auto ">
        <h1 className="text-green-950 mr-10 text-center font-['Inter'] text-[1.93581rem] lg:text-[2.46313rem] font-bold leading-[40px] md:leading-[49.212px] tracking-tighter self-center md:ml-px">
          How to Invest in luupli?
        </h1>
        <div className="self-center w-full ml-1  mt-4">
          <div className=" lg:gap-1 lg:flex md:grid md:grid-cols-2 ">
            <div className="flex flex-col items-stretch w-[90%] lg:w-3/12 ">
              <div className="items-start flex flex-col md:mt-8 ">
                <div className="self-stretch flex w-[80%] md:w-[80%] md:h-[32rem] lg:h-[33rem] flex-col bg-teal-400 bg-opacity-20 px-5 rounded-xl mb-3">
                  <div className="items-start self-center flex w-[80%] md:w-[100%]  flex-col ml-px mt-8  mb-24">
                    <img
                      loading="lazy"
                      src={searchIcon}
                      className="aspect-square object-cover object-center w-[4rem] h-[4rem] shrink-0"
                      alt="Explore the Opportunity"
                    />
                    <h2 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.4rem] lg:text-[1.4rem] font-bold leading-7 w-[100%]  mt-4">
                      Explore the Opportunity:
                    </h2>
                    <p className="text-green-950 text-[1.125rem] font-['Inter'] lg:text-[1.125rem] leading-7 self-stretch w-[100%]  mt-2 md:pb-14">
                      Take the time to understand luupli's vision, mission, and
                      the potential impact of our disruptive approach to social
                      media.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] lg:w-3/12  ">
              <div className="items-start flex flex-col md:mt-8">
                <div className="self-stretch flex w-[80%] md:w-[80%] md:h-[32rem] lg:h-[33rem] flex-col bg-teal-400 bg-opacity-20 px-5 rounded-xl mb-3">
                  <div className="items-start self-center flex w-[80%] md:w-[100%]  flex-col ml-px mt-8 mb-24">
                    <img
                      loading="lazy"
                      src={messageIcon}
                      className="aspect-square object-cover object-center  w-[4rem] h-[4rem] shrink-0"
                      alt="Reach Out to Us"
                    />
                    <h2 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.4rem] lg:text-[1.4rem] font-bold leading-7 w-[90%]  mt-4">
                      Reach Out to Us:
                    </h2>
                    <p className="text-green-950 text-[1.125rem] lg:text-[1.125rem] font-['Inter'] leading-7 self-stretch w-[100%]  mt-2">
                      Contact our investment team to discuss the investment
                      process and any questions you may have. We're here to
                      assist you every step of the way.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] lg:w-3/12 ">
              <div className="items-start flex flex-col md:mt-8">
                <div className="self-stretch flex w-[80%] md:w-[80%] md:h-[32rem] lg:h-[33rem] flex-col bg-teal-400 bg-opacity-20 px-5  rounded-xl mb-3">
                  <div className="items-start self-center flex w-[80%] md:w-[100%]  flex-col mt-10 md:mt-7">
                    <img
                      loading="lazy"
                      src={shieldTick}
                      className="aspect-square object-cover object-center  w-[4rem] h-[4rem] shrink-0"
                      alt="Invest with Confidence"
                    />
                    <h2 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.4rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] mt-4  mb-0">
                      Invest with Confidence:
                    </h2>
                    <p className="text-green-950 text-[1.125rem] font-['Inter'] lg:text-[1.125rem] leading-7 self-stretch w-[100%] lg:mt-6 md:pb-14">
                      Once you decide to invest in luupli's Seedfast, you'll be
                      joining a community of like-minded investors who believe
                      in our mission and vision. Your investment will fuel our
                      growth and help us shape the future of social media.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] lg:w-3/12  md:mr-12">
              <div className="items-start flex flex-col md:mt-8 ">
                <div className="self-stretch flex w-[80%] md:w-[80%] md:h-[32rem] lg:h-[33rem] flex-col bg-teal-400 bg-opacity-20 px-5 rounded-xl">
                  <div className="items-start self-center flex w-[80%] md:w-[100%]  flex-col mt-6 mb-14 md:mb-0">
                    <img
                      loading="lazy"
                      src={enjoyIcon}
                      className="aspect-square object-cover object-center  w-[4rem] h-[4rem] shrink-0"
                      alt="Enjoy the Journey"
                    />
                    <h2 className="flex-[1_0_0] text-green-950 text-[1.4rem] font-['Inter'] lg:text-[1.4rem] font-bold leading-7 w-[100%] lg:-mt-0.5 ">
                      Enjoy the Journey:
                    </h2>
                    <p className="text-green-950 text-[1.125rem] lg:text-[1.125rem] font-['Inter'] leading-7 w-[100%] ">
                      As a luupli investor, you'll be part of an exciting
                      journey, witnessing the transformation of the social media
                      landscape and the positive impact we make on society.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main className=" self-center bg-gradient-to-br from-lime-100 via-white to-emerald-100 w-screen md:w-[100%] md:p-24">
        <article className="self-center mr-2 flex w-100 md:w-[80%] lg:w-[90%] xl:w-[70%] md:mx-auto flex-col bg-gradient-to-r from-lime-100 via-slate-50 to-emerald-100 bg bg-opacity-100 mt-4 md:mt-12 md:py-10  md:rounded-3xl ">
          <h1 className="text-green-950 md:text-center font-['Inter'] text-[2.3425rem] lg:text-[2.46313rem] font-bold leading-[40px] md:leading-[49.212px] tracking-tighter self-center w-[90%] md:w-[100%] ml-10 mt-5 mb-0">
            How to Get Started?
          </h1>
          <p className="text-green-950 md:text-center  text-[ 1.08263rem] lg:text-[1.775rem] font-['Inter']  font-medium md:font-normal leadiing-7 md:leading-10 md:self-center ml-10 w-[80%] md:w-[90%] xl:w-[70%] mb-2 ">
            Don't miss the opportunity to invest in a groundbreaking idea that
            combines innovation, impact, and profit potential. Join us at luupli
            and be a part of the future of social media. Together, we'll create
            a world where technology empowers, connects, and inspires. If you're
            interested in hearing more, write to us on
            <a
              className=" px-1 text-green-950 font-['Inter']"
              href="mailto:invest@luupli.com"
              target="_blank"
              rel="noreferrer"
            >
              invest@luupli.com.
            </a>
          </p>
        </article>
      </main>
      <article className=" self-stretch flex w-screen  flex-col bg-green-950 px-5 py-10">
        <h1 className="text-white md:text-center mr-5 font-['Inter'] text-[1.08263rem] lg:text-[1.775rem] font-medium leading-7 tracking-tighter md:self-center w-[70%] md:w-[50%] ml-8 mt-20 mb-20">
          Invest in luupli's seedfast today and become a catalyst for positive
          change in the social media industry!
        </h1>
      </article>
    </section>
  );
};

export default Invest;
