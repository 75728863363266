import React, { forwardRef } from 'react'

const CustomInput = forwardRef((props, ref) => {
    return (
        <input
            ref={ref}
            {...props}
            className="rounded-4xs-9 box-border w-full h-[52px] items-stretch justify-start  px-4   placeholder:text-gray-600 placeholder:text-sm bg-gray-50"
            placeholder="XXX - XXX - XXXX"
        />
    );
});

export default CustomInput;

// export default CustomInput