import React from "react";
import close from '../assets/Close.png'

export const CreatorsWaitlist = ({ onClose }) => {

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
      <div className="items-center self-center justify-center bg-white flex flex-col px-5 rounded-3xl w-[70%] md:w-[50%] mr-5 lg:w-[440px] md:mt-[50vh] xl:mb-0">
        <button className="justify-end items-end bg-white self-center flex w-[90%] flex-col mt-5 ml-16 lg:w-[360px] ">
          <img
            onClick={onClose}
            loading="lazy"
            src={close}
            alt="close"
            className=" hover:bg-slate-100 hover:border-solid hover:border-black"
          />
        </button>
        <section className="items-center self-stretch flex flex-col  mb-7 ">
          <h1 className="text-zinc-600 text-center text-[1.4rem] font-['Inter']  tracking-tight ">
            Thank you for joining the Luupli waitlist. We look forward to
            sharing exciting updates and inviting you to our beta test. Stay
            tuned!
          </h1>
        </section>
      </div>
    </div>
  );
};
