import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.css";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const headerStyles = {
    border:
      !showMenu && window.innerWidth < 1024 ? "1px solid  transparent" : "none",
    boxShadow:
      !showMenu && window.innerWidth < 1024
        ? "0 1px 1px rgba(0, 0, 0, 0.1)"
        : "none",
    // borderTop: !showMenu && window.innerWidth < 1024 ? '1px solid  rgba(0, 0, 0, 0.2)' : 'none'
  };

  return (
    <header
      style={headerStyles}
      className="w-screen lg:mx-auto sticky top-0 z-10 lg:w-full lg:overflow-x-hidden mt-2 ml-0"
    >
      <div className="flex flex-nowrap justify-between items-center mx-auto pt-2  pb-2 bg-white  lg:bg-white cursor-pointer">
        <Link
          to="/"
          className="luupli text-black text-[2rem] sm:h-9 whitespace-nowrap ml-5 mr-6 lg:ml-10 font-bold font-['Inter'] leading-10 cursor-pointer"
        >
          <img src={logo} alt="luupli logo" className=" ml-1" />
        </Link>

        {/* Hamburger Icon for Mobile Screens */}
        <div className="lg:hidden" onClick={toggleMenu}>
          <button className="focus:outline-none bg-white ">
            {showMenu ? (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="h-6 w-6 mr-2 text-black"
              />
            ) : (
              <FontAwesomeIcon
                icon={faBars}
                className="h-6 w-6 mr-2 text-black"
              />
            )}
          </button>
        </div>

        {/* Navigation Links */}
        <nav
          className={`lg:flex ${
            showMenu ? "block" : "hidden"
          } lg:items-center absolute top-16 left-0 bg-white w-full lg:static lg:w-auto lg:bg-transparent lg:mt-0`}
        >
          <ul className="lg:flex lg:gap-10 lg:items-center flex flex-col lg:flex-row list-none  lg:pb-0 pb-12 gap-5 font-['Inter'] w-full">
            <li>
              <NavLink
                exact
                to="/about"
                onClick={closeMenu}
                className={`text-[#94969e] font-['Inter'] text-sm font-semibold leading-[150%] no-underline ${
                  location.pathname === "/about" || location.pathname === "/"
                    ? "text-black font-bold"
                    : ""
                }`}
              >
                About us
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/creators"
                onClick={closeMenu}
                className={`text-[#94969e] font-['Inter'] text-sm font-semibold leading-[150%] no-underline ${
                  location.pathname === "/creators" || location.pathname === "/"
                    ? "text-black font-bold"
                    : ""
                }`}
              >
                Creators
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/business"
                onClick={closeMenu}
                className={`text-[#94969e] font-['Inter'] text-sm font-semibold leading-[150%] no-underline ${
                  location.pathname === "/business" || location.pathname === "/"
                    ? "text-black font-bold"
                    : ""
                }`}
              >
                Business
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/invest"
                onClick={closeMenu}
                className={`text-[#94969e] font-['Inter'] text-sm font-semibold leading-[150%] no-underline ${
                  location.pathname === "/invest" || location.pathname === "/"
                    ? "text-black font-bold"
                    : ""
                }`}
              >
                Invest
              </NavLink>
            </li>
            <li>
              <NavLink
                exact
                to="/contact"
                onClick={closeMenu}
                className={`text-[#94969e] font-['Inter'] text-sm font-semibold leading-[150%] mb-10 no-underline ${
                  location.pathname === "/contact" || location.pathname === "/"
                    ? "text-black font-bold"
                    : ""
                }`}
              >
                Contact
              </NavLink>
            </li>

            <li>
              <NavLink
                exact
                to="/creators"
                onClick={closeMenu}
                className={`p-2 px-4 lg:px-12 rounded-[0.425rem] lg:mr-4 bg-green-600 hover:bg-green-700 text-white font-['Inter'] text-sm font-semibold leading-[100%] mt-2 lg:round no-underline`}
              >
                BETA
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
