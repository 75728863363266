import React from "react";
import businessHero from "../assets/business1.png";
import buinessPhone from "../assets/businessonphone.png";
import photo1 from "../assets/business2.png";
import { Helmet } from "react-helmet";

const Business = () => {
  const isMobile = window.innerWidth < 768;
  return (
    <section className="flex flex-col ">
      <Helmet>
        <title>Business | luupli</title>
      </Helmet>
      <article className="self-stretch flex w-screen flex-col bg-white md:mt-12">
        <div className="items-center self-center w-[100%] ">
          <div className="self-center w-[100%] md:ml-0">
            <div className=" md:gap-5 md:flex">
              <div className="flex flex-col items-center w-[90%] md:w-[58%] ml-3">
                <h1 className="text-green-950 text-[1.875rem] lg:text-[3.50rem] mb-0 md:ml-20 font-medium leading-[28px] md:leading-none tracking-tighter w-[90%] md:w-[100%] md:mt-24 xl:mt-28">
                  <span className="font-bold font-['Inter']">
                    Welcome to luupli: <br />
                  </span>
                  <span className="font-medium font-['Inter']">
                    Empower Your Business
                    <br /> through Purposeful
                    <br /> Partnerships!
                  </span>
                </h1>
              </div>
              <div className="flex flex-col items-stretch w-[90%] md:w-[80%] lg:w-[55%] lg:mt-16 xl:mt-0 md:ml-5 ">
                <div className="items-start flex flex-col ml-7 md:my-auto md:scale-125 md:mr-12">
                  <img
                    loading="lazy"
                    src={businessHero}
                    className="hidden md:block aspect-[0.67] object-cover object-center w-full self-stretch rounded-[37.38094711303711px]"
                    alt="A group of people doing business"
                  />

                  <img
                    loading="lazy"
                    src={buinessPhone}
                    className=" md:hidden aspect-[1.07] object-cover object-center w-full self-stretch rounded-[37.38094711303711px]"
                    alt="A group of people doing business"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <article className="self-stretch w-[100%] mx-auto md:bg-zinc-100 lg:-mt-40 md:px-5 md:w-[100%] md:-mt-36 xl:-mt-48">
        <div className="self-center  w-full md:ml-0  md:mb-12  ">
          <div className=" md:gap-5 md:flex">
            <div className=" items-center w-[90%] md:items-stretch md:w-[65%] ml-5 ">
              <h1 className="text-green-950 text-[1.09375rem] lg:text-[1.775rem] font-['Inter'] font-medium md:font-normal leading-6 md:leading-tight tracking-tight w-[90%] md:w-[100%] xl:mt-24 ">
                Are you a business looking to make a meaningful impact in the
                world while boosting your brand's reach and growth?{" "}
                {isMobile ? <br /> : null}
                {isMobile ? <br /> : null}
                Look no further than luupli, a mission-based social media
                platform that's reshaping the way businesses connect with their
                audiences and communities.
              </h1>
            </div>
            <div className="flex flex-col items-stretch  w-[95%] md:w-[35%] ml-4 md:mr-20 ">
              <img
                loading="lazy"
                src={photo1}
                className="aspect-[1.31] object-cover object-center w-full self-stretch"
                alt="a collage of photos"
              />
            </div>
          </div>
        </div>
      </article>
      <div className="self-stretch w-[95%] md:flex md:flex-col bg-white md:mx-auto">
        <h2 className="text-green-950 mt-10 text-center font-['Inter']  text-[1.83581rem] lg:text-[2.56313rem] font-bold leading-6 md:leading-[49.212px] tracking-tight self-center w-[90%] md:w-[100%]  md:mt-20">
          Why Partner with luupli?
        </h2>
        <div className="self-center w-full  ml-5 md:ml-5 md:mr-5  md:mx-auto ">
          <div className=" md:flex md:gap-5 ">
            <div className="flex flex-col items-stretch w-[90%] md:w-[33%] ">
              <div className="items-start self-stretch flex flex-col md:mt-12">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1a3380a4-7f8d-419d-88da-b5e157b80f8a?apiKey=f5f7c7073a204785b84af34315c6213b&"
                  className="aspect-square object-cover object-center w-16 shrink-0"
                  alt="Join Our Mission"
                />
                <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.13288rem] lg:text-[1.4rem] font-bold leading-6 w-[90%] md:w-[100%]  mt-4">
                  Join Our Mission:
                </h3>
                <p className="self-stretch text-green-950 text-[0.84969rem] font-['Inter'] lg:text-[1.125rem]  leading-6 w-[90%] md:w-[100%]  mt-1">
                  luupli is more than just a social media platform; it's a
                  movement for positive change. By partnering with us, you align
                  your brand with a platform that is dedicated to creating a
                  better world. Your involvement with luupli helps you reach
                  your social and community goals, showing your customers that
                  you're committed to making a difference.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] md:w-[33%] ">
              <div className="items-start self-stretch flex flex-col md:mt-12">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/dd20892a-21f4-4832-8219-bca2825f2538?apiKey=f5f7c7073a204785b84af34315c6213b&"
                  className="aspect-square object-cover object-center w-16 shrink-0"
                  alt="Exclusive Tools for Success"
                />
                <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.13288rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%] mt-4">
                  Exclusive Tools for Success:
                </h3>
                <p className="self-stretch text-green-950 font-['Inter'] text-[0.84969rem] lg:text-[1.125rem] leading-6 w-[90%] md:w-[90%] mt-1">
                  Gain access to luupli's transformative advertising and
                  business tools designed to elevate your brand's presence. Our
                  innovative technology empowers you to create <br /> compelling
                  campaigns that resonate with your target audience and drive
                  results like never before.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] md:w-[33%] ">
              <div className="items-start self-stretch flex flex-col md:mt-12">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2e79e45d-0e83-415e-bb17-0e3edcb4854c?apiKey=f5f7c7073a204785b84af34315c6213b&"
                  className="aspect-square object-cover object-center w-16 shrink-0"
                  alt="Global Reach, Local Impact"
                />
                <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.13288rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%] mt-4">
                  Global Reach, Local Impact:
                </h3>
                <p className="self-stretch text-green-950 font-['Inter']  text-[0.84969rem] lg:text-[1.125rem]  leading-6 w-[90%] md:w-[90%] mt-1">
                  luupli provides a unique opportunity to expand your reach and
                  tap into untapped markets across the globe. Our diverse user
                  base spans countries and demographics, ensuring your message
                  reaches a broader and more diverse audience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="self-center w-[100%  md:mx-auto md:ml md:mr-5 ">
          <div className=" md:flex md:gap-5">
            <div className="flex flex-col items-stretch w-[100%] md:w-[33%] ">
              <div className="items-start self-stretch ml-5 md:ml-0">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fdb18b34-8800-4be5-9601-3b90a9ca84e9?apiKey=f5f7c7073a204785b84af34315c6213b&"
                  className="aspect-square object-cover object-center w-16 shrink-0"
                  alt="Collaborate with Creators"
                />
                <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.13288rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%]  mt-4">
                  Collaborate with Creators:
                </h3>
                <p className="self-stretch text-green-950 font-['Inter'] text-[0.84969rem] lg:text-[1.125rem] leading-6 w-[90%] md:w-[100%] mt-1">
                  Partner with some of the biggest creators in the industry and
                  tap into their engaged and loyal audiences. Our creators bring
                  authenticity and credibility to your brand, enhancing your
                  marketing efforts and building trust with consumers.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] md:w-[33%] ml-5 md:ml-4">
              <div className="items-start self-stretch">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0602a87e-8655-4755-b794-8c291dde3c6b?apiKey=f5f7c7073a204785b84af34315c6213b&"
                  className="aspect-square object-cover object-center w-16 shrink-0"
                  alt="Exclusive Advertising Credits"
                />
                <h3 className="flex-[1_0_0] text-green-950  font-['Inter'] text-[1.13288rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%]  mt-4">
                  Exclusive Advertising Credits:
                </h3>
                <p className="self-stretch text-green-950 font-['Inter'] text-[0.84969rem] lg:text-[1.125rem] leading-6 w-[80%] md:w-[90%] mt-1">
                  As a luupli partner, you'll receive exclusive advertising
                  credits worth up to $100,000 (Terms and conditions apply).
                  These credits help you maximize your marketing budget and
                  achieve your business goals more efficiently.
                </p>
              </div>
            </div>
            <div className="flex flex-col items-stretch w-[90%] md:w-[33%] ml-5 md:ml-3">
              <div className="items-start self-stretch ">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6e5891e9-eeee-425c-828c-0d610f6207b8?apiKey=f5f7c7073a204785b84af34315c6213b&"
                  className="aspect-square object-cover object-center w-16 shrink-0"
                  alt="Empower creators from underserved global communities"
                />
                <h3 className="flex-[1_0_0] text-green-950 font-['Inter'] text-[1.13288rem] lg:text-[1.4rem] font-bold leading-7 w-[90%] md:w-[100%] mt-2 mb-0">
                  Empower creators from
                  <br /> underserved global communities:
                </h3>
                <p className="text-green-950 text-[0.84969rem] font-['Inter'] lg:text-[1.125rem] leading-6 w-[90%] md:w-[100%] mt-1">
                  By partnering with luupli, you're making a significant impact
                  on the lives of creators from underserved global communities
                  around the world. Your support directly contributes to their
                  growth, helping them achieve their dreams while promoting
                  diversity and inclusion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <article className="self-stretch md:flex  md:flex-col w-[100%] md:w-[100%] bg-white md:mt-24  py-10 bg-gradient-to-br from-lime-100 via-white  to-emerald-100 ">
        <div
          className="justify-center items-center self-center flex w-[100%] md:w-[90%] xl:w-[70%] flex-col bg-gradient-to-r from-lime-100 via-slate-50 to-emerald-100 bg-opacity-80  md:mt-20 md:mb-44  py-10 rounded-3xl"
          role="banner"
        >
          <h1 className=" no-underline text-green-950 font-['Inter'] text-[2.4425rem] lg:text-[2.56313rem] md:text-center font-bold leading-[40px] md:leading-[49.212px] tracking-tighter self-center w-[90%] md:w-[100%]  mb-0 mt-7">
            How to Get Started?
          </h1>
          <p className="text-green-950 font-['Inter'] text-[1.18263rem] lg:text-[1.7rem] font-medium md:font-normal leading-7 tracking-normal lg:tracking-wide lg:leading-tight self-center ml-3 w-[90%] md:w-[90%] lg:w-[90%] md:mb-9 mt-6 ">
            Contact our partnership team at{" "}
            <span style={{ whiteSpace: "nowrap" }}>
              <span
                className="pr-2 text-green-950 font-['Inter']"
                href="mailto:advertise@luupli.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                advertise@luupli.com
              </span>
            </span>
            to explore how luupli can help your business thrive, make a positive
            impact, and connect with a global audience. Let's partner for a
            purpose and drive success that matters.
          </p>
        </div>
      </article>

      <section className=" self-stretch  w-[100%] bg-green-950 py-10  ">
        <h1 className="text-white md:text-center text-[1.08263rem] md:text-[1.775rem] ml-6 font-['Inter'] font-medium leading-7 tracking-tight md:leading-tight w-[90%] md:w-[70%] lg:w-[50%] xl:w- md:mx-auto mt-16 mb-20">
          Join luupli and experience the power of purposeful partnerships.
          Together, we can change the world, one luup at a time.
        </h1>
      </section>
    </section>
  );
};

export default Business;
